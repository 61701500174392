<template>
  <scroll-container class="booked-project">
    <container>
      <card-section class="flex items-center justify-center h-100">
        <card>
          <div class="text-center p-0 p-md-4">
            <h1>{{ l('Project booked') }}</h1>

            <img class="pt-4 pb-5" src="../../assets/graphics/approved_proposal.png" />

            <h4>{{ l(message) }}</h4>

            <p class="py-4">
              {{
                l(
                  'Your project is fully booked. Come back to Bolster for updates or to request and approve change-orders.'
                )
              }}.
            </p>

            <btn class="round info mt-4 booked-btn" to="/home">
              <template #icon>
                <font-awesome-icon icon="list-ul" />
              </template>
              {{ l('See projects') }}
            </btn>
          </div>
        </card>
      </card-section>
    </container>
  </scroll-container>
</template>

<script>
import PageMixin from '../mixins/Page'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'

export default {
  mixins: [PageMixin, TranslationMixin],
  name: 'Phone',
  data() {
    return {}
  },
  watch: {},
  computed: {
    message() {
      return (
        this.$route.query.msg ||
        'Thank you for booking with us! We will get in touch as soon as possible to confirm details.'
      )
    }
  },
  methods: {},
  components: {},
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.booked-project {
  &,
  .scroll-container--inner,
  .cc-container,
  .section--container {
    min-height: 100vh;
  }
  .booked-btn {
    width: 80%;
    max-width: 430px;
  }
}
</style>
